import axios from "axios";
import { SERV_API_PRO } from "../constant_config";

const technicianInfo = async (tech_id) => {
  return await axios({
    url: SERV_API_PRO + "api/technician-info/" + tech_id,
    method: "GET",
    timeout: 5000,
  });
};

const technicianList = async (controller) => {
  return await axios({
    url: SERV_API_PRO + "api/technician-list-tracking",
    method: "GET",
    timeout: 5000,
    signal: controller.signal,
  });
};

const trackingInfo = async (controller) => {
  return await axios({
    url: "http://car-tracking-api.ap-southeast-1.elasticbeanstalk.com/api/v2.0/cars",
    method: "GET",
    signal: controller.signal,
    timeout: 5000,
  });
};

const trackingJobList = async (controller) => {
  return await axios({
    url: SERV_API_PRO + "api/tracking-job-list",
    method: "GET",
    signal: controller.signal,
    timeout: 5000,
  });
};

const trackingHistory = async (tech_id, st_date, end_date) => {
  return await axios({
    url:
      "http://car-tracking-api.ap-southeast-1.elasticbeanstalk.com/api/v2.0/cars/" +
      tech_id +
      "?st_date=" +
      st_date +
      "&end_date=" +
      end_date,
    method: "GET",
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export {
  technicianInfo,
  technicianList,
  trackingInfo,
  trackingJobList,
  trackingHistory,
};
