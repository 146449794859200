import React from "react";

const JobInfo = ({
  show,
  jobId,
  location,
  job,
  appointment,
  technicians,
  status,
  contact,
}) => {
  return (
    show && (
      <div className="job-info">
        <p className="job-info-title">หมายเลขงาน: {jobId}</p>
        <p className="job-info-title">
          สถานที่: {location} ติดต่อ: {contact}
        </p>
        <p className="job-info-title">รายละเอียกงาน: {job}</p>
        <p className="job-info-title">วัน-เวลานัดหมาย: {appointment} น.</p>
        <p className="job-info-title">ช่างบริการ: {technicians.toString()}</p>
        <p className="job-info-title">สถานะ: {status}</p>
      </div>
    )
  );
};

export default JobInfo;
