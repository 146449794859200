import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import Select from "react-select";
import moment from "moment";
import { Modal } from "react-bootstrap";
import {
  technicianInfo,
  technicianList,
  trackingInfo,
  trackingJobList,
} from "../api/technician";

import Marker from "./Marker";
import JobMarker from "./JobMarker";
import JobInfo from "./JobInfo";
import { MapKey } from "../constant_config";
import NextIcon from "../assets/image/tracking/next.png";

const RealtimeTracking = () => {
  const [places, setPlaces] = useState([]);
  // const [arrIndex, setArrIndex] = useState(-1);
  const [jobIndex, setJobIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [technicianListMap, setTechnicianListMap] = useState([]);
  const [technicianListFilter, setTechnicianListFilter] = useState([]);
  const [technicianListOption, setTechnicianListOption] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [jobList, setJobList] = useState([]);
  const [modal, setModal] = useState(false);
  const [searchIndex, setSearchIndex] = useState(-1);
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState(null);
  const [techID, setTechID] = useState(0);

  const mapRef = useRef();
  const mapsRef = useRef();
  const directionsService = useRef();
  const directionsRenderer = useRef();
  const intervalID = useRef();

  const statusOption = [
    {
      label: "Online",
      value: true,
    },
    {
      label: "Offline",
      value: false,
    },
  ];

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        let res = await technicianList(controller);
        let jobs = await trackingJobList(controller);
        let tracking = await trackingInfo(controller);

        let technicianLists = res.data;
        let options = [];
        let sortRes = [];

        if (tracking.data) {
          let placeData = tracking.data.sort((a, b) => {
            return new Date(a.updated_datetime) - new Date(b.updated_datetime);
          });

          technicianLists.map((i) => {
            i.show = false;
            i.online = false;
            placeData.map((j) => {
              if (i.tech_id * 1 === j.tech_id * 1) {
                let now = moment();
                let tracking_datetime = moment(j.updated_datetime);

                let timeDiff = now.diff(tracking_datetime, "minutes");

                i.online = timeDiff <= 2 ? true : false;
                i.lat = j.lat;
                i.lng = j.lng;
                i.updated_datetime = j.updated_datetime;
              }

              return j;
            });

            return i;
          });
        }

        if (status !== null) {
          let techData = technicianLists;

          let techDataFilter = techData.filter((item) => {
            return item.online === status;
          });

          techDataFilter.sort((a, b) => {
            if (a.updated_datetime === undefined) {
              return 1;
            } else if (b.updated_datetime === undefined) {
              return -1;
            }
            return new Date(b.updated_datetime) - new Date(a.updated_datetime);
          });

          techDataFilter.map((item, index) => {
            options.push({
              label:
                item.name + " " + item.lastname + " (" + item.employee_id + ")",
              value: item.tech_id,
              lat: item.lat,
              lng: item.lng,
              techID: item.tech_id,
              index: index,
            });

            return item;
          });

          setTechnicianListFilter(techDataFilter);
          setTechnicianListOption(options);
        } else {
          sortRes = [...technicianLists].sort(
            (a, b) => (b.online ? true : false) - (a.online ? true : false)
          );

          sortRes.sort((a, b) => {
            if (a.updated_datetime === undefined) {
              return 1;
            } else if (b.updated_datetime === undefined) {
              return -1;
            }
            return new Date(b.updated_datetime) - new Date(a.updated_datetime);
          });

          sortRes.map((item, index) => {
            options.push({
              label:
                item.name + " " + item.lastname + " (" + item.employee_id + ")",
              value: item.tech_id,
              lat: item.lat,
              lng: item.lng,
              techID: item.tech_id,
              index: index,
            });

            return item;
          });

          setTechnicianListFilter(sortRes.length ? sortRes : technicianLists);
          setTechnicianListOption(options);
        }

        setTechnicianListMap(sortRes.length ? sortRes : technicianLists);
        setPlaces(sortRes.length ? sortRes : technicianLists);

        if (jobs.data) {
          jobList.current = jobs.data ? jobs.data : [];
        }
      } catch (error) {
        console.log(error);
      } finally {
        intervalID.current = setInterval(() => {
          setTrigger(Date.now());
        }, 1000 * 30);
      }
    })();

    return () => {
      controller.abort();

      clearInterval(intervalID.current);
      intervalID.current = undefined;
    };
  }, [jobList, status]);

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        let tracking = await trackingInfo(controller);

        let placeData = tracking.data.sort((a, b) => {
          return new Date(a.updated_datetime) - new Date(b.updated_datetime);
        });

        if (placeData) {
          technicianListMap.map((i) => {
            i.online = false;

            placeData.map((j) => {
              if (i.tech_id * 1 === j.tech_id * 1) {
                let now = moment();
                let tracking_datetime = moment(j.updated_datetime);

                let timeDiff = now.diff(tracking_datetime, "minutes");

                i.online = timeDiff <= 2 ? true : false;
                i.lat = j.lat;
                i.lng = j.lng;
                i.updated_datetime = j.updated_datetime;
              }

              return j;
            });

            return i;
          });

          let sortRes = [...technicianListMap].sort(
            (a, b) => (b.online ? true : false) - (a.online ? true : false)
          );

          sortRes.sort((a, b) => {
            if (a.updated_datetime === undefined) {
              return 1;
            } else if (b.updated_datetime === undefined) {
              return -1;
            }
            return new Date(b.updated_datetime) - new Date(a.updated_datetime);
          });

          if (status !== null) {
            let techDataFilter = sortRes.filter((item) => {
              return item.online === status;
            });

            let options = [];

            techDataFilter.map((item, index) => {
              options.push({
                label:
                  item.name +
                  " " +
                  item.lastname +
                  " (" +
                  item.employee_id +
                  ")",
                value: item.tech_id,
                lat: item.lat,
                lng: item.lng,
                techID: item.tech_id,
                index: index,
              });

              return item;
            });

            setTechnicianListFilter(techDataFilter);
            setTechnicianListOption(options);
          } else {
            let options = [];

            sortRes.map((item, index) => {
              options.push({
                label:
                  item.name +
                  " " +
                  item.lastname +
                  " (" +
                  item.employee_id +
                  ")",
                value: item.tech_id,
                lat: item.lat,
                lng: item.lng,
                techID: item.tech_id,
                index: index,
              });

              return item;
            });

            setTechnicianListFilter(sortRes);
            setTechnicianListOption(options);
          }

          setTechnicianListMap(sortRes);
          setPlaces(sortRes);

          let jobs = await trackingJobList(controller);

          let jobRes = jobs.data.map((i) => {
            jobList.current.map((j) => {
              if (i.id === j.id) {
                i.show = j.show;
              }

              return j;
            });

            return i;
          });

          if (jobRes) {
            jobList.current = jobRes;
          }
        }
      } catch (error) {}
    })();

    return () => {
      controller.abort();
    };
  }, [trigger, jobList, status, technicianListMap]);

  const techInfo = async (tech_id, lat, lng) => {
    setJobIndex(-1); // clear job index

    if (!lat && !lng) {
      setModal(true);
      return;
    }

    let techRes = technicianListMap.map((item) => {
      item.show = false;

      if (item.tech_id === tech_id) {
        item.show = true;
      }

      return item;
    });

    try {
      let res = await technicianInfo(tech_id);

      if (res.data.length) {
        let index = places.findIndex((item) => item.tech_id === tech_id);

        setTechID(tech_id);
        setSearchIndex(index);

        let bounds = [];
        let router = [];
        let jobRes = null;

        jobRes = jobList.current.map((item) => {
          item.show = false;

          let res = item.tech_list.find((e) => e === tech_id);

          if (res) {
            item.show = true;

            bounds.push({
              lat: item.latitude,
              lng: item.longitude,
            });

            router.push({
              lat: item.latitude,
              lng: item.longitude,
              dateTime: item.appointment_datetime,
            });
          }

          return item;
        });

        setTechnicianListMap(techRes);

        if (jobRes) {
          let sortData = jobRes.sort((a, b) => {
            return (
              new Date(a.appointment_datetime) -
              new Date(b.appointment_datetime)
            );
          });

          setJobList(sortData);
        }

        if (bounds.length) {
          bounds.push({
            lat: places.length
              ? places[index]
                ? places[index]?.lat
                  ? places[index].lat
                  : 0
                : 0
              : 0,
            lng: places.length
              ? places[index]
                ? places[index]?.lng
                  ? places[index].lng
                  : 0
                : 0
              : 0,
          });

          router.push({
            lat: places.length
              ? places[index]
                ? places[index]?.lat
                  ? places[index].lat
                  : 0
                : 0
              : 0,
            lng: places.length
              ? places[index]
                ? places[index]?.lng
                  ? places[index].lng
                  : 0
                : 0
              : 0,
            dateTime: 0,
          });

          getMapBounds(mapRef.current, mapsRef.current, bounds);
        } else {
          // set default bound to technician
          bounds.push({
            lat: lat * 1,
            lng: lng * 1,
          });

          // default center of thailand
          bounds.push({
            lat: 19.7315661,
            lng: 99.3578929,
          });

          bounds.push({
            lat: 14.8581185,
            lng: 99.6105393,
          });

          bounds.push({
            lat: 6.1468531,
            lng: 100.073747,
          });

          getMapBounds(mapRef.current, mapsRef.current, bounds);
        }

        setRoute(router);
      }
    } catch (error) {
      console.log(error);

      setTechID(0);
      setSearchIndex(-1);
    }
  };

  const apiIsLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;

    directionsService.current = new maps.DirectionsService();
    directionsRenderer.current = new maps.DirectionsRenderer();
  };

  const getMapBounds = (map, maps, boundData) => {
    const bounds = new maps.LatLngBounds();

    boundData.forEach((item) => {
      bounds.extend(new maps.LatLng(item.lat, item.lng));
    });

    map.fitBounds(bounds, 50);
  };

  const filterStatus = (value) => {
    setTechID(0);
    setJobIndex(-1);
    setHoverIndex(-1);
    setSearchIndex(-1);
    setStatus(value);

    let techDataFilter = technicianListMap.filter((item) => {
      return item.online === value;
    });

    techDataFilter.sort((a, b) => {
      if (a.updated_datetime === undefined) {
        return 1;
      } else if (b.updated_datetime === undefined) {
        return -1;
      }
      return new Date(b.updated_datetime) - new Date(a.updated_datetime);
    });

    let options = [];
    techDataFilter.map((item, index) => {
      options.push({
        label: item.name + " " + item.lastname + " (" + item.employee_id + ")",
        value: item.tech_id,
        lat: item.lat,
        lng: item.lng,
        index: index,
      });

      return item;
    });

    setTechnicianListFilter(techDataFilter);
    setTechnicianListOption(options);
  };

  const clear = () => {
    setTechID(0);
    setJobIndex(-1);
    setHoverIndex(-1);
    setSearchIndex(-1);
    setStatus(null);

    let bounds = [];

    let techRes = technicianListMap.map((item) => {
      item.show = false;

      if (item.lat && item.lng) {
        bounds.push({
          lat: item.lat ? item.lat : 14.8581185,
          lng: item.lng ? item.lng : 99.6105393,
        });
      } else {
        // default center of thailand
        bounds.push({
          lat: 19.7315661,
          lng: 99.3578929,
        });

        bounds.push({
          lat: 14.8581185,
          lng: 99.6105393,
        });

        bounds.push({
          lat: 6.1468531,
          lng: 100.073747,
        });
      }

      return item;
    });

    let jobRes = jobList.map((item) => {
      item.show = false;

      return item;
    });

    setTechnicianListMap(techRes);
    setTechnicianListFilter(techRes);

    if (jobRes) {
      let sortData = jobRes.sort((a, b) => {
        return (
          new Date(a.appointment_datetime) - new Date(b.appointment_datetime)
        );
      });

      setJobList(sortData);
    }

    getMapBounds(mapRef.current, mapsRef.current, bounds);
    directionsRenderer.current.setMap(null);
  };

  const setRoute = (data) => {
    directionsRenderer.current.setMap(null);

    if (data.length) {
      directionsRenderer.current.setMap(mapRef.current);

      let sortData = data.sort((a, b) => {
        return new Date(a.dateTime) - new Date(b.dateTime);
      });

      const waypoints = sortData.map((p) => ({
        location: { lat: p.lat * 1, lng: p.lng * 1 },
        stopover: true,
      }));

      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;

      directionsService.current
        .route({
          origin: origin,
          destination: destination,
          travelMode: mapsRef.current.TravelMode.DRIVING,
          waypoints: waypoints,
        })
        .then((result) => {
          directionsRenderer.current.setDirections(result);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const hoverInfo = (tech_id, index) => {
    setHoverIndex(index);

    technicianListMap.map((item) => {
      item.show = false;

      if (item.tech_id === tech_id) {
        item.show = true;
      }

      return item;
    });
  };

  return (
    <div className="body">
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="modal-title"
        >
          <Modal.Title>ไม่พบข้อมูลช่างบริการ</Modal.Title>
        </Modal.Header>
      </Modal>
      <div
        className="left-container"
        style={{
          width: open ? "300px" : 0,
        }}
      >
        <div className="technician-list-container">
          {technicianListFilter
            ? technicianListFilter.map((item, index) => (
                <div
                  className="technician-info-card"
                  key={index}
                  onClick={() => techInfo(item.tech_id, item.lat, item.lng)}
                >
                  <img
                    alt="profile"
                    src={
                      "https://api.saijo-denki.com/img/club/upload/profile_img/" +
                      item.profile_img
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src =
                        "https://api.saijo-denki.com/img/club/upload/profile_img/user.png";
                    }}
                    className="technician-info-card-image"
                    style={{
                      borderColor: item.online ? "#55DE2F" : "#b31117",
                    }}
                  />
                  <div className="technician-info-card-title-container">
                    <p>ชื่อ: {item.name + " " + item.lastname}</p>
                    <p>รหัสพนักงาน: {item.employee_id}</p>
                    <p>
                      สถานะ:{" "}
                      <span
                        style={{ color: item.online ? "#55DE2F" : "#b31117" }}
                      >
                        {item.online ? "Online" : "Offline"}
                      </span>
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div
        className="right-container"
        style={{
          width: open ? "calc(100% - 300px)" : "100%",
          marginLeft: open ? "10px" : 0,
        }}
      >
        <div className="filter-container">
          <div className="toggle-btn" onClick={() => setOpen(!open)}>
            <img
              src={NextIcon}
              alt="next"
              className="next-icon"
              style={{ transform: open ? "scaleX(-1)" : "scaleX(1)" }}
            />
          </div>
          <p>ช่างบริการ</p>
          <div style={{ width: "30%", marginLeft: "10px" }}>
            <Select
              onChange={(e) => {
                e ? techInfo(e.value, e.lat, e.lng) : setSearchIndex(-1);
              }}
              value={
                searchIndex === -1 ? null : technicianListOption[searchIndex]
              }
              options={technicianListOption}
              placeholder="Select Technician"
              isClearable={true}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "#F6F7FB",
                  fontFamily: "SukhumvitSet-Medium",
                  border: 0,
                  boxShadow: "none",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: "SukhumvitSet-Medium",
                }),
              }}
            />
          </div>
          <p>สถานะ</p>
          <div style={{ width: "20%", marginLeft: "10px" }}>
            <Select
              onChange={(e) => {
                e ? filterStatus(e.value) : filterStatus(null);
              }}
              value={
                status === true
                  ? statusOption[0]
                  : status === false
                  ? statusOption[1]
                  : null
              }
              options={statusOption}
              placeholder="Select Status"
              isClearable={true}
              isSearchable={false}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "#F6F7FB",
                  fontFamily: "SukhumvitSet-Medium",
                  border: 0,
                  boxShadow: "none",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: "SukhumvitSet-Medium",
                }),
              }}
            />
          </div>
          <button className="clear-btn" onClick={() => clear()}>
            Clear
          </button>
        </div>
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: MapKey,
            }}
            defaultCenter={{ lat: 13.8463263, lng: 100.5308361 }}
            defaultZoom={6}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              apiIsLoaded(map, maps);
            }}
            options={{
              fullscreenControl: false,
            }}
          >
            {places.map((item, index) => (
              <Marker
                lat={item.lat ? item.lat : 0}
                lng={item.lng ? item.lng : 0}
                tech_id={item.tech_id}
                name={item.name + " " + item.lastname}
                employeeID={item.employee_id}
                phoneNumber={item.phone_number}
                timeStamp={item.updated_datetime}
                online={item.online}
                show={
                  item.show
                    ? true
                    : item.tech_id === techID || index === hoverIndex
                    ? true
                    : false
                }
                key={index}
                onClick={() => {
                  techInfo(item.tech_id, item.lat, item.lng);
                }}
                onMouseOver={() => {
                  hoverInfo(item.tech_id, index);
                }}
                onMouseLeave={() => {
                  setHoverIndex(-1);
                }}
              />
            ))}
            {jobList.map((item, index) => (
              <JobMarker
                lat={item.latitude}
                lng={item.longitude}
                key={index}
                show={item.show ? true : index === jobIndex ? true : false}
                showInfo={index === jobIndex ? true : false}
                location={item.company_name}
                appointment={moment(item.appointment_datetime).format(
                  "DD/MM/YYYY HH:mm"
                )}
                technicians={item.tech_name_list}
                status={item.status}
                jobId={item.id}
                onClick={() => setJobIndex(index)}
              />
            ))}
          </GoogleMapReact>
        </div>
        <h5 className="job-list-title">Job Lists</h5>
        <div className="job-info-container">
          {jobList.map((item, index) => (
            <JobInfo
              jobId={item.id}
              location={item.company_name}
              job={item.job}
              appointment={moment(item.appointment_datetime).format(
                "DD/MM/YYYY HH:mm"
              )}
              contact={item.contact}
              technicians={item.tech_name_list}
              status={item.status}
              key={index}
              show={item.show ? true : false}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RealtimeTracking;
