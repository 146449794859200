import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { Modal } from "react-bootstrap";

import { technicianList, trackingHistory } from "../api/technician";
import { MapKey } from "../constant_config";

const History = () => {
  const [technicians, setTechnicians] = useState([]);
  const [technicianListOption, setTechnicianListOption] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(0);
  const [addressList, setAddressList] = useState([]);
  const [techID, setTechID] = useState(0);
  const [searchIndex, setSearchIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const mapRef = useRef();
  const mapsRef = useRef();
  const directionsService = useRef();
  const directionsRenderer = useRef();
  const markerRef = useRef([]);

  const dateRange = [
    {
      label: "+0 วัน",
      value: 0,
    },
    {
      label: "+1 วัน",
      value: 1,
    },
    {
      label: "+2 วัน",
      value: 2,
    },
    {
      label: "+3 วัน",
      value: 3,
    },
    {
      label: "+4 วัน",
      value: 4,
    },
    {
      label: "+5 วัน",
      value: 5,
    },
    {
      label: "+6 วัน",
      value: 6,
    },
    {
      label: "+7 วัน",
      value: 7,
    },
  ];

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        let res = await technicianList(controller);

        let technicianLists = res.data;
        let options = [];

        technicianLists.map((item, index) => {
          options.push({
            label:
              item.name + " " + item.lastname + " (" + item.employee_id + ")",
            value: item.tech_id,
            index: index,
          });

          return item;
        });

        setTechnicians(technicianLists);
        setTechnicianListOption(options);
      } catch (e) {}
    })();
  }, []);

  const apiIsLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;

    directionsService.current = new maps.DirectionsService();
    directionsRenderer.current = new maps.DirectionsRenderer();
  };

  const historyInfo = async () => {
    if (techID) {
      try {
        let res = await trackingHistory(
          techID,
          moment(startDate).format("YYYY-MM-DD"),
          moment(addDays(startDate, endDate)).format("YYYY-MM-DD")
        );

        directionsRenderer.current.setMap(null);

        if (res.data.carhistory) {
          directionsRenderer.current.setMap(mapRef.current);

          let carHistory = res.data.carhistory;

          // prevent way point more than 26
          if (carHistory.length > 26) {
            for (let index = 0; index < carHistory.length - 26; index++) {
              carHistory.shift();
            }
          }

          let waypoints = [];

          carHistory.map((p) => {
            waypoints.push({
              location: { lat: p.lat * 1, lng: p.lng * 1 },
              stopover: true,
            });

            return p;
          });

          let origin = [];
          let destination = [];

          if (markerRef.current.length) {
            for (let i = 0; i < markerRef.current.length; i++) {
              markerRef.current[i].setMap(null);
            }
          }

          if (waypoints.length > 1) {
            origin = waypoints.shift().location;
            destination = waypoints.pop().location;

            directionsService.current
              .route({
                origin: origin,
                destination: destination,
                travelMode: mapsRef.current.TravelMode.DRIVING,
                waypoints: waypoints,
                language: "th",
              })
              .then((result) => {
                let address = [];
                let data = [];

                if (result.status === "OK") {
                  const geocoder = new mapsRef.current.Geocoder();
                  let placeId = result.geocoded_waypoints[0].place_id;

                  geocoder
                    .geocode({ placeId: placeId })
                    .then((res) => {
                      address.push(res.results[0].formatted_address);

                      result.routes.map((i) => {
                        i.legs.map((j) => {
                          address.push(removePrefix(j.end_address));

                          return j;
                        });

                        return i;
                      });

                      carHistory.map((item, index) => {
                        data.push({
                          address: removePrefix(address[index]),
                          datetime: moment(item.datetime).format(
                            "DD/MM/YYYY - HH:mm"
                          ),
                        });

                        return item;
                      });

                      setAddressList(data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }

                directionsRenderer.current.setDirections(result);
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            directionsRenderer.current.setMap(null);

            let map = mapRef.current;

            let marker = new mapsRef.current.Marker({
              position: waypoints[0].location,
              map,
            });

            markerRef.current.push(marker);

            let bounds = [];

            bounds.push({
              lat: waypoints[0].location.lat * 1,
              lng: waypoints[0].location.lng * 1,
            });

            getMapBounds(mapRef.current, mapsRef.current, bounds);

            const geocoder = new mapsRef.current.Geocoder();

            geocoder
              .geocode({
                location: {
                  lat: waypoints[0].location.lat * 1,
                  lng: waypoints[0].location.lng * 1,
                },
              })
              .then((response) => {
                let address = [];
                let data = [];

                if (response.results[0]) {
                  address.push(response.results[0].formatted_address);

                  carHistory.map((item, index) => {
                    data.push({
                      address: removePrefix(address[index]),
                      datetime: moment(item.datetime).format(
                        "DD/MM/YYYY - HH:mm"
                      ),
                    });

                    return item;
                  });

                  setAddressList(data);
                } else {
                  window.alert("No results found");
                }
              })
              .catch((e) => window.alert("Geocoder failed due to: " + e));
          }
        } else {
          setAddressList([]);
          setModal(true);
          setModalMessage("ไม่พบข้อมูลช่างบริการ");
        }
      } catch (e) {
        directionsRenderer.current.setMap(null);
        setAddressList([]);
        setModal(true);
        setModalMessage("ไม่พบข้อมูลช่างบริการ");
      }
    } else {
      setModal(true);
      setModalMessage("โปรดเลือกช่างบริการ");
    }
  };

  const removePrefix = (val) => {
    if (val.includes("+")) {
      return val.substring(9);
    }

    return val;
  };

  const getMapBounds = (map, maps, boundData) => {
    const bounds = new maps.LatLngBounds();

    boundData.forEach((item) => {
      bounds.extend(new maps.LatLng(item.lat, item.lng));
    });

    map.fitBounds(bounds, 50);
  };

  const clear = () => {
    let bounds = [];

    technicians.map((item) => {
      if (item.lat && item.lng) {
        bounds.push({
          lat: item.lat ? item.lat : 14.8581185,
          lng: item.lng ? item.lng : 99.6105393,
        });
      } else {
        // default center of thailand
        bounds.push({
          lat: 19.7315661,
          lng: 99.3578929,
        });

        bounds.push({
          lat: 14.8581185,
          lng: 99.6105393,
        });

        bounds.push({
          lat: 6.1468531,
          lng: 100.073747,
        });
      }

      return item;
    });

    if (markerRef.current.length) {
      for (let i = 0; i < markerRef.current.length; i++) {
        markerRef.current[i].setMap(null);
      }
    }

    getMapBounds(mapRef.current, mapsRef.current, bounds);
    directionsRenderer.current.setMap(null);

    setAddressList([]);

    setStartDate(new Date());
    setEndDate(0);
    setSearchIndex(-1);
    setTechID(0);
  };

  return (
    <div className="body">
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="modal-title"
        >
          <Modal.Title>{modalMessage}</Modal.Title>
        </Modal.Header>
      </Modal>
      <div className="right-container">
        <div className="filter-container">
          <p>ช่างบริการ</p>
          <div style={{ width: "25%", marginLeft: "15px" }}>
            <Select
              onChange={(e) => {
                setTechID(e ? e.value : 0);
                setSearchIndex(e ? e.index : -1);
              }}
              value={
                searchIndex === -1 ? "null" : technicianListOption[searchIndex]
              }
              options={technicianListOption}
              placeholder="Select Technician"
              isClearable={true}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#F6F7FB",
                  borderColor: state.isFocus ? "#F6F7FB" : "#F6F7FB",
                  fontFamily: "SukhumvitSet-Medium",
                  border: 0,
                  boxShadow: "none",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: "SukhumvitSet-Medium",
                }),
              }}
            />
          </div>
          <p>วันที่เริ่ม</p>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control input-search date-input"
            maxDate={new Date()}
          />
          <p>วันที่สิ้นสุด</p>
          <div style={{ marginLeft: "15px" }}>
            <Select
              onChange={(e) => {
                setEndDate(e ? e.value : 0);
              }}
              options={dateRange}
              placeholder="Select Date"
              defaultValue={dateRange[0]}
              value={dateRange[endDate]}
              isSearchable={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#F6F7FB",
                  borderColor: state.isFocus ? "#F6F7FB" : "#F6F7FB",
                  fontFamily: "SukhumvitSet-Medium",
                  border: 0,
                  boxShadow: "none",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: "#888888",
                  fontFamily: "SukhumvitSet-Medium",
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: "SukhumvitSet-Medium",
                }),
              }}
            />
          </div>
          <button className="search-btn" onClick={() => historyInfo()}>
            Search
          </button>
          <button className="clear-btn" onClick={() => clear()}>
            Clear
          </button>
        </div>
        <div className="history-map-container">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: MapKey,
            }}
            defaultCenter={{ lat: 13.8463263, lng: 100.5308361 }}
            center={{ lat: 13.8463263, lng: 100.5308361 }}
            defaultZoom={6}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              apiIsLoaded(map, maps);
            }}
            options={{
              fullscreenControl: false,
            }}
          ></GoogleMapReact>
        </div>
      </div>
      <div className="left-container">
        <div
          style={{
            overflowY: "auto",
            height: "100%",
          }}
        >
          <div className="timeline-container">
            <h5>Timeline</h5>
            <p>
              {moment(startDate).format("DD/MM/YYYY") +
                " - " +
                moment(addDays(startDate, endDate)).format("DD/MM/YYYY")}
            </p>
            {addressList.length ? (
              addressList.map((item, index) => (
                <div className="timeline-info" key={index}>
                  <p className="timeline-location">
                    {addressList.length !== 1
                      ? alphabet[index] + " - " + item.address
                      : item.address}
                  </p>
                  <p className="timeline-date-time">{item.datetime} น.</p>
                </div>
              ))
            ) : (
              <p className="timeline-no-data">ไม่พบข้อมูล</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
