import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/image/logo.png";
import TrackingIcon from "../assets/image/nav/tracking.png";
// import ReportIcon from "../assets/image/nav/report.png";

const NavLeft = (props) => {
  const navigate = useNavigate();

  return (
    <div className="left-nav-container">
      <div className="nav-logo-container">
        <img
          src={Logo}
          alt="Saijo Denki"
          className="logo-icon"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="nav-menu-container">
        <div
          className={props.page === "home" ? "nav-menu active" : "nav-menu"}
          onClick={() => navigate("/")}
        >
          <img
            src={TrackingIcon}
            alt={"tracking"}
            className={"nav-menu-icon"}
          />
        </div>
        {/* <div
          className={props.page === "report" ? "nav-menu active" : "nav-menu"}
          onClick={() => navigate("/report")}
        >
          <img src={ReportIcon} alt={"report"} className={"nav-menu-icon"} />
        </div> */}
      </div>
      <div className="nav-footer"></div>
    </div>
  );
};

export default NavLeft;
