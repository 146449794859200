import React, { useState } from "react";
import NavLeft from "../component/NavLeft";
import RealtimeTracking from "../component/RealtimeTracking";
import History from "../component/History";
// import { io } from "socket.io-client";

const Home = (props) => {
  const [page, setPage] = useState("realtime_tracking");

  // const socket = io(
  //   "http://car-tracking-api.ap-southeast-1.elasticbeanstalk.com",
  //   {
  //     reconnectionDelay: 5000,
  //     reconnectionAttempts: 10,
  //     reconnection: true,
  //   }
  // );

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("connected");
  //   });

  //   socket.on("welcome", (res) => {
  //     console.log(res);
  //   });

  //   socket.on("/", (res) => {
  //     console.log(res);
  //   });

  //   return () => {
  //     socket.disconnect();

  //     console.log("disconnect");
  //   };
  // }, []);

  return (
    <div className="app">
      <NavLeft page={props.page} />
      <div className="main">
        <div className="top-nav-container">
          <div className="nav-navigation-container">
            <div
              className={page === "realtime_tracking" ? "active" : ""}
              onClick={() => setPage("realtime_tracking")}
            >
              Realtime Tracking
            </div>
            <div
              className={page === "history" ? "active" : ""}
              onClick={() => setPage("history")}
            >
              Tracking History
            </div>
          </div>
        </div>
        {page === "realtime_tracking" && <RealtimeTracking />}
        {page === "history" && <History />}
      </div>
    </div>
  );
};

export default Home;
