import React from "react";
import car from "../assets/image/tracking/car.png";
import moment from "moment";

const Marker = ({
  tech_id,
  onClick,
  name,
  employeeID,
  phoneNumber,
  show,
  lat,
  lng,
  onMouseOver,
  onMouseLeave,
  timeStamp,
  online,
}) => {
  return (
    <div
      className="wrapper"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{
        zIndex: show ? 1000 : 1,
        visibility: lat * 1 && lng * 1 ? "visible" : "hidden",
      }}
    >
      <img src={car} alt="car" className="marker-logo" />
      <div
        className="marker-title-container"
        style={{ display: show ? "flex" : "none" }}
      >
        <div className="marker-image-container">
          <img
            alt="profile"
            src={
              "https://api.saijo-denki.com/img/club/upload/profile_img/" +
              tech_id +
              ".png"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://api.saijo-denki.com/img/club/upload/profile_img/user.png";
            }}
            className="marker-image"
          />
        </div>
        <div className="info-container">
          <p className="info-text">ชื่อ: {name}</p>
          <p className="info-text">รหัสพนักงาน: {employeeID}</p>
          <p className="info-text">เบอร์โทร: {phoneNumber}</p>
          <p className="info-text">
            ออนไลน์ล่าสุด:{" "}
            <span style={{ color: online ? "#55DE2F" : "#b31117" }}>
              {moment(timeStamp).format("DD/MM/YYYY HH:mm:ss")} น.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Marker;
