import React from "react";
import NavLeft from "../component/NavLeft";
import PageNotFoundIcon from "../assets/image/page_not_found/page_not_found.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PageNotFound = (props) => {
  const navigate = useNavigate();

  return (
    <div className="app">
      <NavLeft page={null} />
      <div className="main">
        <div className="top-nav-container"></div>
        <div className="body">
          <div className="page-not-found-container">
            <div className="page-not-found-body">
              <div className="page-not-found-image-container">
                <img
                  className="page-not-found-image"
                  alt="page-not-found"
                  src={PageNotFoundIcon}
                />
              </div>
              <div className="page-not-found-message-container">
                <p className="page-not-found-title mb-3">Oops!</p>
                <p className="page-not-found-sub-title mb-0">
                  PAGE NOT FOUND ON THIS SERVER
                </p>
                <p className="page-not-found-text my-3 my-xl-5 mx-4">
                  We're sorry, the page you requested could not be found. Please
                  go back to the homepage!
                </p>
                <Button className="homepage-btn" onClick={() => navigate("/")}>
                  Go to Homepage
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
