import React from "react";

const JobMarker = ({
  show,
  showInfo,
  jobId,
  location,
  appointment,
  technicians,
  status,
  onClick,
}) => {
  return (
    show && (
      <div
        className="wrapper-job"
        style={{ zIndex: show ? 1001 : 1 }}
        onClick={onClick}
      >
        <div
          className="marker-jobs-title-container"
          style={{ display: showInfo ? "flex" : "none" }}
        >
          <p className="job-title">หมายเลขงาน: {jobId}</p>
          <p className="job-title">สถานที่: {location}</p>
          <p className="job-title">วัน-เวลานัดหมาย: {appointment} น.</p>
          <p className="job-title">ช่างบริการ: {technicians.toString()}</p>
          <p className="job-title">สถานะ: {status}</p>
        </div>
      </div>
    )
  );
};

export default JobMarker;
